import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"
import { useStatefulCookie } from "~/composables/useStatefulCookie"

export const useLoginRegistration = () => {
  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)

  const {
    authToken,
    refreshToken,
    commerceLayerToken,
    _ALGOLIA: cookieAlgolia
  } = {
    authToken: useStatefulCookie("authToken", { maxAge: 86400 }),
    refreshToken: useStatefulCookie("refreshToken", { maxAge: 2592000 }),
    commerceLayerToken: useStatefulCookie("cmlToken", { maxAge: 3600 }),
    _ALGOLIA: useStatefulCookie("_ALGOLIA")
  }
  const errorMessage = ref<string | undefined>(undefined)

  const handleAuth = async (authPromise: Promise<any>) => {
    try {
      errorMessage.value = undefined
      const response = await authPromise
      if (typeof response === "string") {
        if (response === "reset_password") {
          errorMessage.value = "reset_password"
        } else {
          errorMessage.value = response
        }
      } else {
        const {
          authToken: token,
          refreshToken: rToken,
          commerceLayerToken: clToken
        } = response
        authToken.value = token
        refreshToken.value = rToken
        commerceLayerToken.value = clToken
        cookieAlgolia.value = userId.value
        errorMessage.value = undefined

        if (process.client && location) {
          location.reload()
        }
      }
    } catch (error) {
      errorMessage.value = "An error occurred."
      console.warn("ERROR: ", error)
    }
  }

  const handleLogin = (fields: { email: string; password: string }) => {
    handleAuth(userStore.login(fields))
  }

  const handleRegistration = (fields: {
    email: string
    password: string
    subscribeNewsletter: boolean
  }) => {
    handleAuth(userStore.signup(fields))
  }

  return {
    errorMessage,
    handleLogin,
    handleRegistration
  }
}
